<template>
  <div id="header-mobile" class="header">
    <BaseImg id="logo-mobile" class="logo" :src="LOGO_SITE_IMAGE" alt="logo" @click="$router.push(PAGE_URLS.HOME)" />
    <div class="header-button">
      <ClientOnly>
        <MobileHeaderLoginButton v-if="!currentUser" />
        <MobileHeaderUserLogged v-else />
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import { PAGE_URLS } from '~/config/page-url'
import MobileHeaderLoginButton from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
const { currentUser } = useHeader()
const $router = useRouter()
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/index.scss" />
